<template>
  <div>
    微信群发
  </div>
</template>

<script>
export default {
  setup () {
    

    return {}
  }
}
</script>

<style lang="less" scoped>

</style>